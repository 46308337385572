@import '~antd/dist/antd.css';

body,
html {
  height: 100%;
  background-color: #fff;
}

body,
html,
.ant-typography {
  color: #555;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
}

b {
  color: #555;
}

.ant-typography ul {
  list-style-type: disc;
  padding-top: 5px;
}

.ant-typography ul > li {
  padding-top: 5px;
  padding-bottom: 5px;
}

.ant-dropdown-menu {
  box-shadow: 6px 5px 4px 3px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 26%), 0 9px 28px 8px rgb(0 0 0 / 44%);
}

.ant-collapse-item.faqPanel .ant-collapse-header {
  font-weight: 550;
  font-size: 1.15em;
  color: #666;
  background-color: #f3f3f3;
  margin-top: 1em;
}
.ant-collapse-item.faqPanel .ant-collapse-content-box {
  border: 1px solid #eee;
  border-bottom: 0px solid red;
  background-color: #fff;
}

.interurbanScheduleRowEven {
  background-color: transparent;
}
.interurbanScheduleRowOdd {
  background-color: #f4f4f4;
}

.coachPhoto {
  float: right;
  width: 30vw;
  min-width: 220px;
  max-width: 350px;
  height: auto;
  margin-left: 2em;
  margin-bottom: 2em;
}

ul.ulPadded li {
  padding-top: 10px;
}

.noPad {
  padding: 0 !important;
  margin: 0 !important;
}
.noWrap {
  white-space: nowrap;
}
.padRight {
  padding: 0 1em 0 0 !important;
  margin: 0 !important;
}

.upcomingBookingLink {
  display: inline-block;
  cursor: pointer !important;
  width: 100%;
}
.upcomingBookingLink:hover {
  /* background-color: #d8f1d5 !important; */
  text-decoration: underline;
}

#root {
  min-height: 100%;
}

.infoPanel {
  font-size: 0.95em;
}

.form {
  background-color: #f3f3f3;
  border: 1px solid #bbb;
  padding: 1.5em;
}

.calendarMonth div.ant-card-head {
  background-color: #fff;
  min-height: 1em;
  color: #666;
}
.calendarMonth div.ant-card-head div.ant-card-head-wrapper {
}
.calendarMonth div.ant-card-head div.ant-card-head-wrapper div.ant-card-head-title {
  padding: 0;
}

/* Court bookings */
.bookingModalHeader {
  font-size: 1.5em;
}

.bookingNextBookingBlocked,
.bookingNextBookingBlocked b {
  /* color: rgb(182, 57, 26); */
  font-size: 1.1em;
}
.bookingNextBookingAvailable {
  color: green;
}
.bookingLastBooked {
  color: blue;
}
.bookingTable {
  margin-top: 1em;
  margin-left: auto;
  margin-right: auto;
  max-width: 80em;
}
.bookingCourtName {
  text-align: center;
  font-weight: bold;
}
.bookingRow {
  display: flex;
}
.bookingTime {
  position: relative;
  top: -8px;
  width: 50px;
  margin-right: 1em;
  text-align: right;
  font-size: 0.8em;
  white-space: nowrap;
}
.bookingTimeslot {
  min-height: 2em;
  position: relative;
  background-color: #fff;
  outline: 1px solid #bbb;
  padding: 5px;
}
.bookingTimeslot.available:hover {
  background-color: #ffffe1;
  background-color: #fbfbfb;
  border: 1px solid #ddd;
  z-index: 1000;
}

.bookingUpcoming {
  font-size: 1em;
  color: #999;
  overflow: hidden;
}
.bookingUpcoming .date {
  /* font-size: 0.9em; */
  color: #333;
}
.bookingUpcoming .time {
  /* font-size: 0.9em; */
  color: #333;
}
.bookingUpcoming .court {
  font-size: 0.9em;
  color: #999;
  padding-left: 0.5em;
}
.bookingUpcoming .players {
  font-size: 0.9em;
  color: #777;
  padding-left: 0.5em;
}

.bookingDateHeading {
  font-size: 1.3em;
  font-weight: bold;
  margin-right: 2em;
  vertical-align: baseline;
}
.bookingHeading {
  padding-top: 0px;
}
.bookingActions {
  visibility: hidden;
  position: absolute;
  background-color: #ffffaa;
  background-color: white;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.1);

  top: 1px;
  right: 1px;
  padding-right: 5px;
  padding: 5px;
  border-radius: 5px;
  /* border: 1px solid #eee; */
}
.bookingTimeslot:hover .bookingActions {
  visibility: visible;
  z-index: 1;
}
.bookingTimeslot:hover {
  /* border: 1px solid #ddd;
  z-index: 1000; */
}
.bookingActions button {
  height: 32px;
  width: 32px;
  transition: none;
}
.bookingActions button:hover {
  background-color: #ffffaa;
  border: 1px solid #bbb;
}
.bookingActionIcon {
  margin-left: 8px;
  margin-right: 8px;
  color: #333;
  color: black;
}
.bookingLastMinute {
  color: #999;
  font-size: 12px;
  display: inline;
  padding-right: 0.5em;
}
.bookingOwner {
  top: 0;
  left: 0;
  color: #333;
  font-size: 0.95em;
  padding-bottom: 3px;
  display: inline-block;
}
.bookingDescription {
  color: blue;
}
.bookingComment {
  color: #666;
  font-size: 0.9em;
}
.bookingPlayers {
  font-size: 0.8em;
  color: #999;
  display: inline;
  padding-left: 1em;
}
.bookingTag {
  position: absolute;
  bottom: 0;
  right: 0;
}
.bookingTag .ant-tag {
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  font-size: 11px;
}
.bookingDescription.multiCourt,
.bookingComment.multiCourt {
  padding-top: 1em;
  text-align: center;
  font-size: 1.1em;
}
@media only screen and (max-width: 576px) {
  .bookingDateHeading {
    font-size: 1.1em;
    text-align: center;
  }
}
.bookingTimeslot.spacer {
  height: 3em;
  border-top: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
  outline: transparent;
  background-color: #fafafa;
  background: repeating-linear-gradient(135deg, #fafafa, #fafafa 10px, #fff 10px, #fff 20px);
}

.bookingTimeslot.multiCourt {
  background: repeating-linear-gradient(135deg, #f9ffe7, #f9ffe7 10px, #fff 10px, #fff 20px);
  background: repeating-linear-gradient(135deg, #fafafa, #fafafa 10px, #fff 10px, #fff 20px);
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bookingTimeslot.blocked {
  background-color: #eee;
}
.bookingTimeslot.notavailable {
  background-color: #fafafa;
  background: repeating-linear-gradient(135deg, #fafafa, #fafafa 10px, #fff 10px, #fff 20px);
}
.bookingTimeslot.available {
  background-color: #fff;
  cursor: pointer;
}
.bookingTimeslot.booked {
  /* background-color: #f9ffe79f; */
  background-color: #f4f9fa;
}
.bookingTimeslot.bookedByCurrentUser {
  background-color: #eaffe3;
}
.bookingTypeLesson {
  background-color: #f3c4c455;
}
.bookingTypeTournament {
  background-color: #d9ddef55;
}
.bookingTypeLadder {
  background-color: #9feb9655;
}

/* Home page */

.homepagePhoto {
  text-align: center;
}
.homepagePhoto img {
  width: auto;
  max-height: 30vh;
  margin-left: auto;
  margin-right: auto;
}

.background {
  background-image: url('assets/images/background.jpg');
  filter: blur(5px) brightness(60%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  transform: scale(1.02);
}

.page {
  max-width: 1400px;
  width: 90%;
  /* height: 100%; */
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding-top: 20px;
}

.page.admin-registrations,
.page.admin-users,
.page.admin-adult-lesson-signups,
.page.admin-camp-signups {
  max-width: none;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.page.courtbookingkiosk .menuRow {
  display: none;
}
.page.courtbookingkiosk .header {
  display: none;
}

.layout {
  /* background-color: #fff; */
  background: transparent;
  height: 100%;
  min-height: calc(100vh - 65px);
}

.header {
  background-color: #fff;
  width: 100%;
  z-index: 1;
  padding: 0;
}

.header .ant-row {
}

.content {
  padding: 0 3em;
  padding-top: 2em;
  padding-bottom: 2em;
  background-color: white;
  min-height: 60vh;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.footer {
  margin-top: 1em;
  text-align: center;
  padding: 8px;
  color: #fff;
  background-color: #f8f8f8;
  font-size: 0.9em;
  background: transparent;
}

.logo {
  height: 120px;
  content: url('assets/images/logo.png');
  top: 0px;
  z-index: -1;
  margin-left: auto;
  margin-right: auto;
}

.ant-modal-header {
  border-bottom: none;
}
.ant-modal-title {
  color: #666;
  font-size: 1.4rem;
}

.ant-col .ant-form-item-label {
  padding-bottom: 0;
}

.ant-form-item-label label {
  font-size: 0.9em;
  color: #888;
}

.note {
  font-size: 0.95em;
  font-style: italic;
}

.contactTable .label {
  font-weight: bold;
  min-width: 10em;
  vertical-align: top;
}

.contactTable .value {
  vertical-align: top;
}

.membershipPricesTable {
  max-width: 50em;
}

.compactTable .ant-table-cell {
  font-size: 13px;
  padding: 3px !important;
}

.registrationSteps {
  margin-top: 2em;
  margin-bottom: 2em;
}

.registrationMemberForm {
  border: 1px solid #ccc;
  background-color: #fcfcfc;
  padding: 1em;
}
.registrationMembersTable .ant-table-cell {
  font-size: 12px;
  padding: 3px !important;
}
.registrationMembersTableSelectedRow {
  background-color: #feffe9;
  font-style: italic;
}
.registrationMembersTable .ant-table-tbody > tr.ant-table-row-level-0 > td {
  vertical-align: top;
}
.registrationMembersTable .ant-table-tbody > tr.ant-table-row-level-0:hover > td {
  background: none;
}
.registrationMembersTable .ant-table-thead > tr > th.ant-table-cell {
  background-color: #fbfbfb;
  font-size: 1em;
  font-weight: normal;
}
#registration-details {
  margin-top: 2em;
}
#registration-details .ant-row.ant-form-item {
  margin-bottom: 10px;
}

.menuRow {
  vertical-align: middle;
  background: transparent;
  background-color: rgba(0, 0, 0, 0.39);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.menuIcon svg {
  color: #9e9ed2;
  font-size: 1.2em;
}

.menuItem {
}

.menuItem button.ant-btn span {
  color: #ddd;
  font-size: 0.9em;
}
.menuItem button.ant-btn:hover {
  background-color: rgba(0, 0, 0, 0.39);
}
.menuItem button.ant-btn span:hover {
  color: #fff;
  transition: all 0.2s;
}

.menu {
  /* float: right; */
  /* line-height: 64px; */
  /* width: calc(100% - 300px); */
}

.ant-menu-horizontal {
  border-bottom: 0;
}

.ant-menu-item:focus-visible {
  box-shadow: none;
}

h1.ant-typography {
  color: #426e91;
  font-size: 1.5rem;
  margin-top: 2rem;
  border-bottom: 1px solid #ccc;
}

h2.ant-typography {
  color: #426e91;
  font-size: 1.2rem;
  margin-top: 2rem;
}

h3.ant-typography {
  color: #666;
  font-size: 1.1rem;
  margin-top: 2rem;
}

h4.ant-typography {
  color: #666;
  font-size: 1rem;
  margin-top: 2rem;
}

h5.ant-typography {
  color: #666;
  font-size: 0.9rem;
}

*.ant-typography.noPad {
  margin-top: 0;
}

.message {
  margin-bottom: 1em;
}

.ant-alert-note {
  margin-top: 1em;
  margin-bottom: 2em;
  background-color: #8cc63e2b;
  border: 1px solid #ccc;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.ant-alert .ant-alert-content .ant-alert-description h1,
.ant-alert .ant-alert-content .ant-alert-description h2 {
  color: #555;
}
.ant-alert .ant-alert-content .ant-alert-description strong {
  color: initial;
}

.ant-alert-bookingTimer {
  border: 1px solid #d4d4d4;
  background-color: #f6f8fc;
  padding-bottom: 0;
}
.ant-alert-bookingUpcoming {
  border: 0px solid #d4d4d4;
  background-color: white;
}

.ant-alert-general {
  border: 1px solid #d4d4d4;
  background-color: #f6f8fc;
}
.ant-alert-cancelledWeather {
  border: 1px solid #d4d4d4;
  background-color: #eff7ff;
  background-image: url('assets/images/rain.png');
  background-position: right 5px top 5px;
  background-repeat: no-repeat;
  background-size: 40px;
}

.filledCard {
  background-color: #f3f3f3;
  border: 1px solid #999;
}

@media only screen and (min-width: 1600px) {
  .header {
    height: 100px;
  }
  .page {
    /* border-bottom: 2px solid red; */
  }
  .menuItem button.ant-btn span {
    font-size: 1em;
  }
}

@media only screen and (max-width: 1600px) {
  .header {
    height: 100px;
  }
  .page {
    /* border-bottom: 2px solid pink; */
  }
  .menuItem button.ant-btn span {
    font-size: 1em;
  }
}
@media only screen and (max-width: 1200px) {
  .page {
    /* border-bottom: 2px solid orange; */
  }
  .logo {
    height: 120px;
  }
  .menuItem button.ant-btn span {
    font-size: 1em;
  }
}
@media only screen and (max-width: 992px) {
  .page {
    /* border-bottom: 2px solid green; */
  }
  .logo {
    height: 105;
  }
  .menuItem button.ant-btn.ant-btn-link {
    font-size: 0.95em;
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media only screen and (max-width: 768px) {
  .page {
    /* border-bottom: 2px solid blue; */
    padding-top: 10px;
    width: 95%;
  }
  .content {
    padding: 20px;
    padding-top: 0px;
  }
  .logo {
    height: 100px;
  }
  .menuItem button.ant-btn {
    padding-left: 8px;
    padding-right: 8px;
  }
  .menuItem button.ant-btn.ant-btn-link {
    font-size: 0.9em;
  }
}
@media only screen and (max-width: 666px) {
  .page {
    /* border-bottom: 2px solid purple; */
  }
  .menuItem button.ant-btn {
    padding-left: 5px;
    padding-right: 5px;
  }
  .menuItem button.ant-btn.ant-btn-link {
    font-size: 0.8em;
  }
}
@media only screen and (max-width: 576px) {
  .page {
    /* border-bottom: 2px solid gray; */
    padding-top: 0;
    width: 100%;
  }
  .logo {
    height: 90px;
    /* filter: opacity(0.8); */
    /* content: url('assets/images/logo-sm.png'); */
  }
  .content {
    margin-top: 0px;
    padding: 20px;
    padding-top: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .menuRow {
    background-color: rgba(0, 0, 0, 0.8);
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    position: fixed;
    z-index: 100;
    width: 100%;
  }
  .menuItem button.ant-btn {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .header {
    height: 140px;
    padding-top: 45px;
  }
  header.header div {
    justify-content: center;
  }
  .footer {
    height: 30px;
    margin-top: 0em;
    background-color: rgba(0, 0, 0, 0.39);
  }
  h1.ant-typography {
    /* text-align: center; */
  }
}
